import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import ListingScreen from "../../mycomponents/CRUD/ListingScreen";




export const collaborationRequestfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            type: 'divider',
            heading: 'Institution/Organization Information',
            visible: true,
            span: 24,
            tab: 'General'
        },
        {
            label: 'Institution Name',
            key: 'institution_name',
            type: 'text',
            placeholder: 'Name of Institution/Organization',
            visible: true,
            width: '25%',
            tab: 'General',
            required: true
        },
        {
            label: 'Institution Type',
            key: 'institution_type',
            type: 'select',
            options: [
                { label: 'University', value: 'University' },
                { label: 'College', value: 'College' },
                { label: 'Research Institute', value: 'Research Institute' },
                { label: 'Corporate', value: 'Corporate' },
                { label: 'NGO', value: 'NGO' }
            ],
            placeholder: 'Select Institution Type',
            visible: true,
            width: '25%',
            tab: 'General',
            required: true
        },
        {
            label: 'Address',
            key: 'address',
            type: 'textarea',
            placeholder: 'Institution Address',
            visible: true,
            width: '50%',
            tab: 'General',
            required: true
        },
        {
            label: 'City',
            key: 'city',
            type: 'text',
            placeholder: 'City',
            visible: true,
            width: '25%',
            tab: 'General',
            required: true
        },
        {
            label: 'State',
            key: 'state',
            type: 'text',
            placeholder: 'State',
            visible: true,
            width: '25%',
            tab: 'General',
            required: true
        },
        {
            label: 'Country',
            key: 'country',
            type: 'text',
            placeholder: 'Country',
            visible: true,
            width: '25%',
            tab: 'General',
            required: true
        },
        {
            label: 'Website',
            key: 'website',
            type: 'text',
            placeholder: 'Website URL',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Contact Person',
            key: 'contact_person',
            type: 'text',
            placeholder: 'Contact Person Name',
            visible: true,
            width: '25%',
            tab: 'General',
            required: true
        },
        {
            label: 'Designation',
            key: 'designation',
            type: 'text',
            placeholder: 'Designation/Title',
            visible: true,
            width: '25%',
            tab: 'General',
            required: true
        },
        {
            label: 'Email',
            key: 'email',
            type: 'text',
            placeholder: 'Email Address',
            visible: true,
            width: '25%',
            tab: 'General',
            required: true
        },
        {
            label: 'Phone',
            key: 'phone',
            type: 'text',
            placeholder: 'Contact Number',
            visible: true,
            width: '25%',
            tab: 'General',
            required: true
        },
        {
            type: 'divider',
            heading: 'Collaboration Details',
            visible: true,
            span: 24,
            tab: 'General'
        },
        {
            label: 'Collaboration Types',
            key: 'collaboration_types',
            type: 'multiselect',
            options: [
                { label: 'Conference', value: 'Conference' },
                { label: 'Seminar', value: 'Seminar' },
                { label: 'Webinar', value: 'Webinar' },
                { label: 'Workshop', value: 'Workshop' },
                { label: 'Event', value: 'Event' },
                { label: 'Memorandum of Understanding (MoU)', value: 'MoU' },
                { label: 'Others', value: 'Others' }
            ],
            placeholder: 'Select Collaboration Types',
            visible: true,
            width: '50%',
            tab: 'General',
            required: true
        },
        {
            label: 'Title',
            key: 'title',
            type: 'text',
            placeholder: 'Proposed Title/Theme',
            visible: true,
            width: '50%',
            tab: 'General',
            required: true
        },
        {
            label: 'Objective',
            key: 'objective',
            type: 'textarea',
            placeholder: 'Objective/Purpose of Collaboration',
            visible: true,
            width: '50%',
            tab: 'General',
            required: true
        },
        {
            label: 'Preferred Date',
            key: 'preferred_date',
            type: 'date',
            placeholder: 'Select Preferred Date',
            visible: true,
            width: '25%',
            tab: 'General',
            required: true
        },
        {
            label: 'Duration',
            key: 'duration',
            type: 'select',
            options: [
                { label: 'One Day', value: 'One Day' },
                { label: 'Two Days', value: 'Two Days' },
                { label: 'More', value: 'More' }
            ],
            placeholder: 'Select Duration',
            visible: true,
            width: '25%',
            tab: 'General',
            required: true
        },
        {
            label: 'Target Audience',
            key: 'target_audience',
            type: 'multiselect',
            options: [
                { label: 'Students', value: 'Students' },
                { label: 'Faculty', value: 'Faculty' },
                { label: 'Researchers', value: 'Researchers' },
                { label: 'Industry Professionals', value: 'Industry Professionals' },
                { label: 'Others', value: 'Others' }
            ],
            placeholder: 'Select Target Audience',
            visible: true,
            width: '50%',
            tab: 'General',
            required: true
        },
        {
            label: 'Estimated Participants',
            key: 'estimated_participants',
            type: 'number',
            placeholder: 'Number of Expected Participants',
            visible: true,
            width: '25%',
            tab: 'General',
            required: true
        },
        {
            label: 'SPSR Support',
            key: 'spsr_support',
            type: 'textarea',
            placeholder: 'Resources or Support Requested from SPSR',
            visible: true,
            width: '50%',
            tab: 'General',
            required: true
        },
        {
            label: 'Institution Resources',
            key: 'institution_resources',
            type: 'textarea',
            placeholder: 'Resources Your Institution Will Provide',
            visible: true,
            width: '50%',
            tab: 'General',
            required: true
        },
        {
            label: 'Previous Collaboration',
            key: 'previous_collaboration',
            type: 'select',
            options: [
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
            ],
            placeholder: 'Previous Collaboration with SPSR?',
            visible: true,
            width: '25%',
            tab: 'General',
            required: true,
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const obj = {
                    updateVisiblity: Math.random()
                };
                setObj(obj)
            }
        },
        {
            label: 'Previous Collaboration Details',
            key: 'previous_collaboration_details',
            type: 'textarea',
            placeholder: 'Details of Previous Collaborations',
            visible: (value) => value?.previous_collaboration === 'Yes',
            width: '50%',
            tab: 'General',
            required: (value) => value?.previous_collaboration === 'Yes'
        },
        {
            label: 'Additional Comments',
            key: 'additional_comments',
            type: 'textarea',
            placeholder: 'Any Additional Comments or Information',
            visible: true,
            width: '50%',
            tab: 'General'
        },
        {
            label: 'Proposal Document',
            key: 'proposal_document',
            type: 'upload',
            placeholder: 'Upload Proposal Document',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Supporting Documents',
            key: 'supporting_document',
            type: 'upload',
            placeholder: 'Upload Supporting Documents',
            visible: true,
            width: '25%',
            tab: 'General'
        }
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                tab: 'General'
            });
        });
    }

    return formFields;
};

export const collaborationRequestMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const collaborationRequestMain = {
    fields: collaborationRequestfields,
    mobileCard: null
}