import React, { useEffect, useState } from "react";
import { View, Card, Text, Button, TouchableOpacity } from "react-native-ui-lib";
import { Col, CustomCheckbox, CustomDatePicker, CustomNumberInput, CustomSelect, CustomTextField, DangerButton, Label, Row } from "../../mycomponents/DynamicForm";
import { Platform, Touchable } from "react-native";
import { addButton } from "../hrms/shiftModel";
import { Icon } from "react-native-eva-icons";
import { RND } from "../accountancy/transaction";
import ReportScreenSingle from "../../mycomponents/CRUD/ReportScreenSingle";

export const employeefields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const DegreesComponent = React.memo(({ field, useForm, tKey, tIndex }) => {
        const degrees = useForm((state) => state.formObject['degrees']);
        const setFormObject = useForm((state) => state.setFormObject);
        const getFormObject = useForm((state) => state.getFormObject);

        return <>
            <View flex>
                {degrees && degrees.map((degree, index) => {
                    return <Row key={index} style={{ marginBottom: 10, alignItems: 'center' }} >
                        <Col width={Platform.OS != 'web' ? '100%' : '50%'}>
                            <View centerV style={{ width: '100%' }}>
                                {Label({ label: 'Degree Name' })}
                                <CustomTextField value={degree.name} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.degrees];
                                    newArray[index].name = value;
                                    newObject.degrees = newArray;
                                    setFormObject(newObject);
                                }} />
                            </View>
                        </Col>
                        <Col width={Platform.OS != 'web' ? '100%' : '25%'}>
                            <View centerV style={{ width: '100%' }}>
                                {Label({ label: 'Year of completion' })}
                                <CustomDatePicker value={degree.date} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.degrees];
                                    newArray[index].date = value;
                                    newObject.degrees = newArray;
                                    setFormObject(newObject);
                                }} />
                            </View>
                        </Col>


                        <View style={{ marginTop: 18 }}>
                            <Button outline outlineColor={'red'} label="Delete" onPress={() => {
                                const newObject = { ...getFormObject() };
                                const newArray = [...newObject.degrees];
                                newArray.splice(index, 1);
                                newObject.degrees = newArray;
                                setFormObject(newObject);
                            }} />
                        </View>
                    </Row>
                })}
            </View>
        </>
    });

    const SchoolsComponent = React.memo(({ field, useForm, tKey, tIndex }) => {
        const schools = useForm((state) => state.formObject['schools']);
        const setFormObject = useForm((state) => state.setFormObject);
        const getFormObject = useForm((state) => state.getFormObject);

        return <>
            <View flex>
                {schools && schools.map((school, index) => {
                    return <Row key={index} style={{ marginBottom: 10, alignItems: 'center' }} >
                        <Col width={Platform.OS != 'web' ? '100%' : '50%'}>
                            <View centerV style={{ width: '100%' }}>
                                {Label({ label: 'School Name' })}
                                <CustomTextField value={school.name} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.schools];
                                    newArray[index].name = value;
                                    newObject.schools = newArray;
                                    setFormObject(newObject);
                                }} />
                            </View>
                        </Col>
                        <Col width={Platform.OS != 'web' ? '100%' : '25%'}>
                            <View centerV style={{ width: '100%' }}>
                                {Label({ label: 'Year of completion' })}
                                <CustomDatePicker value={school.date} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.schools];
                                    newArray[index].date = value;
                                    newObject.schools = newArray;
                                    setFormObject(newObject);
                                }} />
                            </View>
                        </Col>


                        <View style={{ marginTop: 18 }}>
                            <Button outline outlineColor={'red'} label="Delete" onPress={() => {
                                const newObject = { ...getFormObject() };
                                const newArray = [...newObject.schools];
                                newArray.splice(index, 1);
                                newObject.schools = newArray;
                                setFormObject(newObject);
                            }} />
                        </View>
                    </Row>
                })}
            </View>
        </>
    });

    const CertificatesComponent = React.memo(({ field, useForm, tKey, tIndex }) => {
        const certificates = useForm((state) => state.formObject['certificates']);
        const setFormObject = useForm((state) => state.setFormObject);
        const getFormObject = useForm((state) => state.getFormObject);

        return <>
            <View flex>
                {certificates && certificates.map((certificate, index) => {
                    return <Row key={index} style={{ marginBottom: 10, alignItems: 'center' }} >
                        <Col width={Platform.OS != 'web' ? '100%' : '50%'}>
                            <View centerV style={{ width: '100%' }}>
                                {Label({ label: 'Certificate Name' })}
                                <CustomTextField value={certificate.name} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.certificates];
                                    newArray[index].name = value;
                                    newObject.certificates = newArray;
                                    setFormObject(newObject);
                                }} />
                            </View>
                        </Col>
                        <Col width={Platform.OS != 'web' ? '100%' : '25%'}>
                            <View centerV style={{ width: '100%' }}>
                                {Label({ label: 'Certificate Date' })}
                                <CustomDatePicker value={certificate.date} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.certificates];
                                    newArray[index].date = value;
                                    newObject.certificates = newArray;
                                    setFormObject(newObject);
                                }} />
                            </View>
                        </Col>


                        <View style={{ marginTop: 18 }}>
                            <Button outline outlineColor={'red'} label="Delete" onPress={() => {
                                const newObject = { ...getFormObject() };
                                const newArray = [...newObject.certificates];
                                newArray.splice(index, 1);
                                newObject.certificates = newArray;
                                setFormObject(newObject);
                            }} />
                        </View>
                    </Row>
                })}
            </View>
        </>
    });

    const LeaveApproverComponent = React.memo(({ field, useForm, tKey, tIndex }) => {
        const leave_approvers = useForm((state) => state.formObject['leave_approvers']);
        const setFormObject = useForm((state) => state.setFormObject);
        const getFormObject = useForm((state) => state.getFormObject);

        return <>
            <View flex>
                {leave_approvers && leave_approvers.map((leave_approver, index) => {
                    return <Row key={index} style={{ marginBottom: 10, alignItems: 'center' }} >
                        <Col width={Platform.OS != 'web' ? '100%' : '75%'}>
                            <View centerV style={{ width: '100%' }}>
                                {Label({ label: 'Approvers' })}
                                <CustomSelect value={leave_approver.approver_id} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.leave_approvers];
                                    newArray[index].approver_id = value;
                                    newObject.leave_approvers = newArray;
                                    setFormObject(newObject);
                                }} options={(response.Employees || []).filter((item) => item._id != value?._id)} multiple />
                            </View>
                        </Col>

                        <View style={{ marginTop: 18 }}>
                            <Button outline outlineColor={'red'} label="Delete" onPress={() => {
                                const newObject = { ...getFormObject() };
                                const newArray = [...newObject.leave_approvers];
                                newArray.splice(index, 1);
                                newObject.leave_approvers = newArray;
                                setFormObject(newObject);
                            }} />
                        </View>
                    </Row>
                })}
            </View>
        </>
    });

    const AllowancesComponent = React.memo(({ field, useForm, tKey, tIndex }) => {
        const allowances = useForm((state) => state.formObject['allowances']);
        const setFormObject = useForm((state) => state.setFormObject);
        const getFormObject = useForm((state) => state.getFormObject);

        return <>
            <View flex>
                {allowances && allowances.map((allowance, index) => {
                    return <Row key={index} style={{ marginBottom: index + 1 != allowances.length ? 10 : 0, alignItems: 'center' }} >
                        <Col style={{ marginBottom: 0 }} width={Platform.OS != 'web' ? '100%' : '25%'}>
                            <View centerV style={{ width: '100%' }}>
                                {Label({ label: 'Allowance Name' })}
                                <CustomSelect value={allowance.allowance_id} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.allowances];
                                    newArray[index].allowance_id = value;
                                    const allowance_details = response.Earnings.find((allowance) => allowance._id == value);
                                    newArray[index].calculation_type = allowance_details.calculation_type;
                                    newArray[index].calculation_value = allowance_details.calculation_value;
                                    newObject.allowances = newArray;
                                    setFormObject(newObject);
                                    adjustSalary("allowances", getFormObject, setFormObject)
                                }} options={response.Earnings || []} />
                            </View>
                        </Col>
                        {allowance.calculation_type && <Col style={{ marginBottom: 0 }} width={Platform.OS != 'web' ? '100%' : '25%'}>
                            {allowance.calculation_type && <View centerV style={{ width: '100%' }}>
                                {Label({ label: allowance.calculation_type })}
                                <CustomNumberInput value={allowance.calculation_value} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.allowances];
                                    newArray[index].calculation_value = value;
                                    if (allowance.calculation_type == 'Percentage') {
                                        newArray[index].amount = (value / 100) * newObject.basic_salary;
                                    } else {
                                        newArray[index].amount = value;
                                    }
                                    newObject.allowances = newArray;
                                    setFormObject(newObject);
                                    adjustSalary("allowances", getFormObject, setFormObject)
                                }} />
                            </View>}
                        </Col>}
                        {allowance.calculation_type && <Col style={{ marginBottom: 0 }} width={Platform.OS != 'web' ? '100%' : '25%'}>
                            <View centerV style={{ width: '100%' }}>
                                {Label({ label: "Amount (Estimated)" })}
                                <CustomNumberInput value={allowance.amount} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.allowances];
                                    newArray[index].amount = value;
                                    newObject.allowances = newArray;
                                    setFormObject(newObject);
                                    adjustSalary("allowances", getFormObject, setFormObject)
                                }} disabled={true} />
                            </View>
                        </Col>}

                        <View style={{ marginTop: 18 }}>
                            <DangerButton label="Delete" onPress={() => {
                                const newObject = { ...getFormObject() };
                                const newArray = [...newObject.allowances];
                                newArray.splice(index, 1);
                                newObject.allowances = newArray;
                                setFormObject(newObject);
                                adjustSalary("allowances", getFormObject, setFormObject)
                            }} />
                        </View>
                    </Row>
                })}
            </View>
        </>
    });

    const DeductionsComponent = React.memo(({ field, useForm, tKey, tIndex }) => {
        const deductions = useForm((state) => state.formObject['deductions']);
        const setFormObject = useForm((state) => state.setFormObject);
        const getFormObject = useForm((state) => state.getFormObject);

        return <>
            <View flex>
                {deductions && deductions.map((deduction, index) => {
                    return <Row key={index} style={{ marginBottom: index + 1 != deductions.length ? 10 : 0, alignItems: 'center' }} >
                        <Col style={{ marginBottom: 0 }} width={Platform.OS != 'web' ? '100%' : '25%'}>
                            <View centerV style={{ width: '100%' }}>
                                {Label({ label: 'Deduction Name' })}
                                <CustomSelect value={deduction.deduction_id} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.deductions];
                                    newArray[index].deduction_id = value;
                                    const deduction_details = response.Deductions.find((deduction) => deduction._id == value);
                                    newArray[index].calculation_type = deduction_details.calculation_type;
                                    newArray[index].calculation_value = deduction_details.calculation_value;
                                    newObject.deductions = newArray;
                                    setFormObject(newObject);
                                    adjustSalary("deductions", getFormObject, setFormObject)
                                }} options={response.Deductions || []} />
                            </View>
                        </Col>
                        {deduction.calculation_type && <Col style={{ marginBottom: 0 }} width={Platform.OS != 'web' ? '100%' : '25%'}>
                            {deduction.calculation_type && <View centerV style={{ width: '100%' }}>
                                {Label({ label: deduction.calculation_type })}
                                <CustomNumberInput value={deduction.calculation_value} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.deductions];
                                    newArray[index].calculation_value = value;
                                    if (deduction.calculation_type == 'Percentage') {
                                        newArray[index].amount = (value / 100) * newObject.basic_salary;
                                    } else {
                                        newArray[index].amount = value;
                                    }
                                    newObject.deductions = newArray;
                                    setFormObject(newObject);
                                    adjustSalary("deductions", getFormObject, setFormObject)
                                }} />
                            </View>}
                        </Col>}
                        {deduction.calculation_type && <Col style={{ marginBottom: 0 }} width={Platform.OS != 'web' ? '100%' : '25%'}>
                            <View centerV style={{ width: '100%' }}>
                                {Label({ label: "Amount (Estimated)" })}
                                <CustomNumberInput value={deduction.amount} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.deductions];
                                    newArray[index].amount = value;
                                    newObject.deductions = newArray;
                                    setFormObject(newObject);
                                    adjustSalary("deductions", getFormObject, setFormObject)
                                }} disabled={true} />
                            </View>
                        </Col>}

                        <View style={{ marginTop: 18 }}>
                            <DangerButton label="Delete" onPress={() => {
                                const newObject = { ...getFormObject() };
                                const newArray = [...newObject.deductions];
                                newArray.splice(index, 1);
                                newObject.deductions = newArray;
                                setFormObject(newObject);
                                adjustSalary("deductions", getFormObject, setFormObject)
                            }} />
                        </View>
                    </Row>
                })}
            </View>
        </>
    });

    const StatutoryDeductionsComponent = React.memo(({ field, useForm, tKey, tIndex }) => {
        const statutory_components = useForm((state) => state.formObject['statutory_components']);
        const setFormObject = useForm((state) => state.setFormObject);
        const getFormObject = useForm((state) => state.getFormObject);

        const ActualStatutoryComponents = response.StatutoryComponents;

        if (statutory_components && statutory_components.length != ActualStatutoryComponents.length) {
            const newObject = { ...getFormObject() };
            const newArray = [...(newObject.statutory_components || [])];
            ActualStatutoryComponents.forEach((statutory_component) => {
                if (!newArray.find((statutory_component) => statutory_component.deduction_id == statutory_component._id)) {

                    if (statutory_component.name == "Income Tax") {
                        newArray.push({
                            ...statutory_component,
                            employer_contribution: 0,
                            employee_contribution: 0,
                            override: false,
                            regime: "New"
                        })
                    } else {
                        newArray.push({
                            ...statutory_component,
                            employer_contribution: 0,
                            employee_contribution: 0,
                            override: false
                        })
                    }
                }
            })
            newObject.statutory_components = newArray;
            setFormObject(newObject);
        }

        return <>
            <View flex>
                {statutory_components && statutory_components.map((statutory_component, index) => {
                    return <Row key={index} style={{ marginBottom: index + 1 != statutory_component.length ? 10 : 0, alignItems: 'flex-start' }} >
                        <Col style={{ marginBottom: 0 }} width={Platform.OS != 'web' ? '100%' : '25%'}>
                            <View centerV style={{ width: '100%' }}>
                                {Label({ label: 'Statutory Component Name' })}
                                <Text >{statutory_component?.name}</Text>
                            </View>
                        </Col>
                        <Col style={{ marginBottom: 0 }} width={Platform.OS != 'web' ? '100%' : '25%'}>
                            {!(["Income Tax"].includes(statutory_component?.name)) && <View centerV style={{ width: '100%' }}>
                                {Label({ label: "Employer Contribution (Estimated)" })}
                                <CustomNumberInput placeholder={"(Autogenerated)"} value={statutory_component.employer_contribution} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.statutory_components];
                                    newArray[index].employer_contribution = value;
                                    newObject.statutory_components = newArray;
                                    setFormObject(newObject);
                                    adjustSalary("statutory_components", getFormObject, setFormObject)
                                }}
                                    disabled={!statutory_component.override}
                                />
                            </View>}

                            {(["Income Tax"].includes(statutory_component?.name)) && <View centerV style={{ width: '100%' }}>
                                {Label({ label: "Regime" })}
                                <CustomSelect placeholder={"Select Regime"}
                                    options={[
                                        { label: "New", value: "New" },
                                        { label: "Old", value: "Old" }
                                    ]}
                                    value={statutory_component.regime} onChange={(value) => {
                                        const newObject = { ...getFormObject() };
                                        const newArray = [...newObject.statutory_components];
                                        newArray[index].regime = value;
                                        newObject.statutory_components = newArray;
                                        setFormObject(newObject);
                                        adjustSalary("statutory_components", getFormObject, setFormObject)
                                    }}
                                />
                            </View>}

                        </Col>
                        <Col style={{ marginBottom: 0 }} width={Platform.OS != 'web' ? '100%' : '25%'}>
                            <View centerV style={{ width: '100%' }}>
                                {Label({ label: statutory_component.name == 'Income Tax' ? "TDS (Estimated Monthly)" : "Employee Contribution (Estimated)" })}
                                <CustomNumberInput placeholder={"(Autogenerated)"} value={statutory_component.employee_contribution} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.statutory_components];
                                    newArray[index].employee_contribution = value;
                                    newObject.statutory_components = newArray;
                                    setFormObject(newObject);
                                    adjustSalary("statutory_components", getFormObject, setFormObject)
                                }}
                                    disabled={!statutory_component.override}
                                />
                            </View>
                        </Col>

                        <Col style={{ marginBottom: 0 }} width={Platform.OS != 'web' ? '100%' : '10%'}>
                            {!(["Income Tax"].includes(statutory_component?.name)) && <View centerV style={{ width: '100%' }}>
                                {Label({ label: "Override" })}
                                <CustomCheckbox value={statutory_component.override} onChange={(value) => {
                                    const newObject = { ...getFormObject() };
                                    const newArray = [...newObject.statutory_components];
                                    newArray[index].override = value;
                                    newObject.statutory_components = newArray;
                                    setFormObject(newObject);
                                    adjustSalary("statutory_components", getFormObject, setFormObject)
                                }} />
                            </View>}
                        </Col>
                    </Row>
                })}
            </View>
        </>
    });

    const NetIncomeHeading = React.memo(({ field, useForm, tKey, tIndex }) => {
        const net_monthly = useForm((state) => state.formObject['net_monthly']);

        return <View flex>
            <View style={{
                borderBottomColor: '#d9d9d9',
                borderBottomWidth: 1,
                width: '100%',
                marginBottom: 10,
                marginTop: 10,
                borderStyle: 'dashed',
            }} />
            <Row style={{ alignItems: 'flex-start' }} >
                <Col style={{ marginBottom: 0 }} width={Platform.OS != 'web' ? '100%' : '50%'}>
                    <Text text70 style={{ fontWeight: 'bold', lineHeight: 38 }}>Net Income</Text>
                </Col>
                <Col style={{ marginBottom: 0 }} width={Platform.OS != 'web' ? '100%' : '25%'}>
                    <CustomTextField value={net_monthly} onChange={(value) => {
                    }} disabled={true} />
                </Col>
            </Row>
            <View style={{
                borderBottomColor: '#d9d9d9',
                borderBottomWidth: 1,
                width: '100%',
                marginBottom: 0,
                marginTop: 10,
                borderStyle: 'dashed',
            }} />
        </View>
    });

    const GrossIncomeHeading = React.memo(({ field, useForm, tKey, tIndex }) => {
        const gross_monthly = useForm((state) => state.formObject['gross_monthly']);

        return <View flex>
            <View style={{
                borderBottomColor: '#d9d9d9',
                borderBottomWidth: 1,
                width: '100%',
                marginBottom: 10,
                marginTop: 10,
                borderStyle: 'dashed',
            }} />
            <Row style={{ alignItems: 'flex-start' }} >
                <Col style={{ marginBottom: 0 }} width={Platform.OS != 'web' ? '100%' : '50%'}>
                    <Text text70 style={{ fontWeight: 'bold', lineHeight: 38 }}>Gross Income</Text>
                </Col>
                <Col style={{ marginBottom: 0 }} width={Platform.OS != 'web' ? '100%' : '25%'}>
                    <CustomTextField value={gross_monthly} onChange={(value) => {
                    }} disabled={true} />
                </Col>
            </Row>
            <View style={{
                borderBottomColor: '#d9d9d9',
                borderBottomWidth: 1,
                width: '100%',
                marginBottom: 0,
                marginTop: 10,
                borderStyle: 'dashed',
            }} />
        </View>
    });

    const LedgerAccountComponent = React.memo(({ field, useForm, tKey, tIndex }) => {
        const account_id = useForm((state) => state.formObject['account_id']);

        return <View flex>
            <ReportScreenSingle
                moduleName={"Ledger"}
                hideWrapper={true}
                noMono={true}
                hideHeader={true}
                params={{
                    quickFilterKey: 'account_id',
                    quickFilterValue: account_id,
                    hideQuickFilter: true,
                }}
                route={{
                    params: {
                        quickFilterKey: 'account_id',
                        quickFilterValue: account_id,
                        hideQuickFilter: true,
                        name: "Ledger",
                    }
                }}
            />
        </View>
    });

    const formFields = [
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter name',
            visible: true,
            width: '25%',
            tab: 'Personal Details'
        },
        {
            label: 'Email',
            key: 'email',
            type: 'text',
            placeholder: 'Enter email',
            visible: true,
            width: '25%',
            tab: 'Personal Details'
        },
        {
            label: 'Contact Number',
            key: 'contact_number',
            type: 'text',
            placeholder: 'Enter contact number',
            visible: true,
            width: '25%',
            tab: 'Personal Details',
            showCountryCode: true
        },
        // {
        //     label: 'Residency Status',
        //     key: 'residency_status',
        //     type: 'text',
        //     placeholder: 'Enter residency status',
        //     visible: true,
        //     width: '25%',
        //     tab: 'Personal Details'
        // },
        // {
        //     label: 'Nationality',
        //     key: 'nationality',
        //     type: 'text',
        //     placeholder: 'Enter nationality',
        //     visible: true,
        //     width: '25%',
        //     tab: 'Personal Details'
        // },
        // {
        //     label: 'Place of Birth',
        //     key: 'place_of_birth',
        //     type: 'text',
        //     placeholder: 'Enter place of birth',
        //     visible: true,
        //     width: '25%',
        //     tab: 'Personal Details'
        // },
        // {
        //     label: 'Marital Status',
        //     key: 'marital_status',
        //     type: 'text',
        //     placeholder: 'Enter marital status',
        //     visible: true,
        //     width: '25%',
        //     tab: 'Personal Details'
        // },
        {
            label: 'Gender',
            key: 'gender',
            type: 'select',
            placeholder: 'Select gender',
            visible: true,
            width: '25%',
            options: [
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
                { label: 'Other', value: 'Other' },
            ],
            tab: 'Personal Details'
        },
        // {
        //     label: 'Religion',
        //     key: 'religion',
        //     type: 'text',
        //     placeholder: 'Enter religion',
        //     visible: true,
        //     width: '25%',
        //     tab: 'Personal Details'
        // },
        // {
        //     label: 'Date of Birth',
        //     key: 'dob',
        //     type: 'date',
        //     placeholder: 'Enter date of birth',
        //     visible: true,
        //     width: '25%',
        //     tab: 'Personal Details'
        // },
        // {
        //     label: 'National ID',
        //     key: 'national_id',
        //     type: 'text',
        //     placeholder: 'Enter national ID',
        //     visible: true,
        //     width: '25%',
        //     tab: 'Personal Details'
        // },

        {
            label: 'Profile Picture',
            key: 'profile_pic',
            type: 'upload',
            placeholder: 'Upload profile picture',
            visible: true,
            width: '25%',
            mimeType: 'image/*',
            moduleName: moduleName,
            tab: 'Personal Details'
        },

        // {
        //     label: '',
        //     key: 'addresses',
        //     type: 'divider',
        //     heading: 'Address',
        // },

        {
            heading: 'Basic Details',
            type: 'divider',
            visible: true,
            tab: 'Employment Details'
        },

        // {
        //     label: 'Department',
        //     key: 'department_id',
        //     type: 'select',
        //     placeholder: 'Select department',
        //     visible: true,
        //     width: '25%',
        //     options: response.Departments || [],
        //     creatableAction: (props) => {
        //         const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };
        //         OpenModalCustom({ newModule: "Departments", field_key: 'department_id', array: true, params })
        //     },
        //     multiple: true,
        //     tab: 'Employment Details'
        // },
        {
            label: 'Designations',
            key: 'designation_ids',
            type: 'select',
            placeholder: 'Select designation',
            visible: true,
            width: '25%',
            options: response.Designations || [],
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };
                OpenModalCustom({ newModule: "Designations", field_key: 'designation_ids', array: true, params })
            },
            multiple: true,
            tab: 'Employment Details'
        },

        {
            label: 'Employee Code',
            key: 'employee_code',
            type: 'text',
            placeholder: 'Enter employee code',
            visible: true,
            width: '25%',
            tab: 'Employment Details',
            value: 'Auto-Generated'
        },

        {
            label: 'Only POS Access',
            key: 'only_pos_access',
            type: 'checkbox',
            placeholder: 'Enter only POS access',
            visible: true,
            width: '25%',
            tab: 'Employment Details',
            value: false
        },

        // {
        //     label: 'Company Email',
        //     key: 'company_email',
        //     type: 'text',
        //     placeholder: 'Enter email',
        //     visible: true,
        //     width: '25%',
        //     tab: 'Employment Details'
        // },

        // {
        //     label: 'Date of Joining',
        //     key: 'doj',
        //     type: 'date',
        //     placeholder: 'Enter date of joining',
        //     visible: true,
        //     width: '25%',
        //     tab: 'Employment Details',
        //     value: new Date()
        // },

        // {
        //     label: 'Employee Type',
        //     key: 'employee_type',
        //     type: 'select',
        //     placeholder: 'Select employee type',
        //     visible: true,
        //     width: '25%',
        //     tab: 'Employment Details',
        //     options: [
        //         { label: 'Permanent', value: 'Permanent' },
        //         { label: 'Contract', value: 'Contract' },
        //         { label: 'Intern', value: 'Intern' },
        //     ],
        //     onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        //         setObj({
        //             updateVisiblity: Math.random(),
        //         })
        //     },
        //     value: 'Permanent'
        // },

        // {
        //     label: 'Working Status',
        //     key: 'working_status',
        //     type: 'select',
        //     placeholder: 'Enter working status',
        //     visible: (obj) => obj.employee_type != 'Contract',
        //     width: '25%',
        //     tab: 'Employment Details',
        //     options: [
        //         { label: 'Working', value: 'Working' },
        //         { label: 'Resigned', value: 'Resigned' },
        //         { label: 'Terminated', value: 'Terminated' },
        //     ],
        //     onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
        //         setObj({
        //             updateVisiblity: Math.random(),
        //         })
        //     },
        //     value: 'Working'
        // },

        // {
        //     label: 'Last Working Date',
        //     key: 'last_working_date',
        //     type: 'date',
        //     placeholder: 'Enter last date',
        //     visible: (obj) => obj.employee_type == 'Contract' || obj.working_status == 'Resigned' || obj.working_status == 'Terminated' || obj.working_status == 'Contract Ended',
        //     width: '25%',
        //     tab: 'Employment Details',
        //     value: null,
        // },

        // {
        //     label: 'Notice Period',
        //     key: 'notice_period',
        //     type: 'text',
        //     placeholder: 'Enter notice period',
        //     visible: true,
        //     width: '25%',
        //     tab: 'Employment Details'
        // },

        // {
        //     label: 'Probation Period',
        //     key: 'probation_period',
        //     type: 'text',
        //     placeholder: 'Enter probation period',
        //     visible: true,
        //     width: '25%',
        //     tab: 'Employment Details'
        // },

        {
            label: 'Locations',
            key: 'locations',
            type: 'select',
            value: [],
            multiple: true,
            placeholder: 'Enter Locations',
            visible: true,
            width: '25%',
            tab: 'Employment Details',
            options: response.Locations || [],
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };
                OpenModalCustom({ newModule: "Locations", array: true, field_key: 'locations', params })
            }
        },

        // {
        //     label: 'Team/User Groups',
        //     key: 'team_id',
        //     type: 'select',
        //     placeholder: 'Select team/group',
        //     visible: true,
        //     width: '25%',
        //     multiple: true,
        //     options: response.Teams || [],
        //     tab: 'Employment Details',
        //     creatableAction: (props) => {
        //         const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };
        //         OpenModalCustom({ newModule: "Teams", field_key: 'team_id', array: true, params })
        //     },
        // },

        {
            label: 'Managers',
            key: 'manager_ids',
            type: 'select',
            placeholder: 'Select managers',
            visible: true,
            width: '25%',
            multiple: true,
            options: (response.Employees || []).filter((item) => item._id != value?._id),
            tab: 'Employment Details'
        },


        {
            label: 'Load Shift',
            key: 'shift_id',
            type: 'select',
            placeholder: 'Select shift',
            visible: true,
            width: '25%',
            options: (response.Shifts || []),
            tab: 'Employment Details',
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };
                OpenModalCustom({ newModule: "Shifts", field_key: 'shift_id', params })
            }
        },

        // {
        //     heading: 'Degrees',
        //     type: 'divider',
        //     visible: true,
        //     tab: 'Education Details'
        // },

        // {
        //     key: 'degrees',
        //     type: 'custom',
        //     visible: true,
        //     component: DegreesComponent,
        //     width: '100%',
        //     value: [],
        //     tab: 'Education Details',
        // },

        // addButton('Add Degrees', 'add_degrees', 'degrees', {}, 'Education Details'),

        // {
        //     heading: 'Schools',
        //     type: 'divider',
        //     visible: true,
        //     tab: 'Education Details'
        // },

        // {
        //     key: 'Schools',
        //     type: 'custom',
        //     visible: true,
        //     component: SchoolsComponent,
        //     width: '100%',
        //     value: [],
        //     tab: 'Education Details',
        // },

        // addButton('Add Schools', 'add_schools', 'schools', {}, 'Education Details'),

        // {
        //     heading: 'Certificates',
        //     type: 'divider',
        //     visible: true,
        //     tab: 'Education Details'
        // },

        // {
        //     key: 'certificates',
        //     type: 'custom',
        //     visible: true,
        //     component: CertificatesComponent,
        //     width: '100%',
        //     value: [],
        //     tab: 'Education Details',
        // },

        // addButton('Add Certificates', 'add_certificates', 'certificates', {}, 'Education Details'),

        {
            label: 'Annual CTC',
            key: 'annual_ctc',
            type: 'number',
            placeholder: 'Enter amount',
            visible: true,
            width: '25%',
            tab: 'Salary Details',
            value: 0,
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                adjustSalary("annual_ctc", getObj, setObj);
            }
        },
        {
            width: '25%',
            tab: 'Salary Details',
        },

        // {
        //     label: 'Gross Monthly',
        //     key: 'gross_monthly',
        //     type: 'number',
        //     placeholder: 'Enter amount',
        //     visible: true,
        //     width: '25%',
        //     tab: 'Salary Details',
        //     value: 0,
        //     onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
        //         adjustSalary("annual_ctc", getObj, setObj);
        //     },
        //     disabled: true,
        // },
        {
            visible: true,
            width: '50%',
            tab: 'Salary Details',
        },
        {
            label: 'Salary Type',
            key: 'salary_type',
            type: 'select',
            placeholder: 'Enter salary type',
            visible: true,
            width: '25%',
            tab: 'Salary Details',
            options: [
                { label: 'Monthly', value: 'Monthly' },
                { label: 'Daily', value: 'Daily' },
                { label: 'Hourly', value: 'Hourly' },
            ],
            value: 'Monthly',
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                adjustSalary("salary_type", getObj, setObj);
            }
        },

        {
            label: 'Salary Amount',
            key: 'salary_amount',
            type: 'number',
            placeholder: 'Enter amount',
            visible: true,
            width: '25%',
            tab: 'Salary Details',
            value: 0,
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                adjustSalary("salary_amount", getObj, setObj);
            }
        },

        {
            label: 'Basic Salary (Estimated)',
            key: 'basic_salary',
            type: 'number',
            placeholder: 'Enter basic salary amount',
            visible: true,
            width: '25%',
            tab: 'Salary Details',
            value: 0,
            disabled: true,
        },

        {
            label: 'Formula',
            key: 'salary_formula',
            type: 'text',
            placeholder: '',
            visible: true,
            width: '25%',
            tab: 'Salary Details',
            value: '1 x Salary Amount',
            disabled: true,
        },

        {
            heading: 'Allowances/Earnings',
            type: 'divider',
            visible: true,
            tab: 'Salary Details'
        },

        {
            key: 'allowances',
            type: 'custom',
            visible: true,
            component: AllowancesComponent,
            span: '100%',
            value: [],
            tab: 'Salary Details',
        },

        addButton('Add Allowances', 'add_allowances', 'allowances', {}, 'Salary Details'),

        {
            heading: 'Deductions',
            type: 'divider',
            visible: true,
            tab: 'Salary Details'
        },

        {
            key: 'deductions',
            type: 'custom',
            visible: true,
            component: DeductionsComponent,
            span: '100%',
            value: [],
            tab: 'Salary Details',
        },

        addButton('Add Deductions', 'add_deductions', 'deductions', {}, 'Salary Details'),

        {
            key: 'statutory_components',
            type: 'custom',
            visible: true,
            component: GrossIncomeHeading,
            span: '100%',
            tab: 'Salary Details',
        },

        {
            heading: 'Statutory Deductions',
            type: 'divider',
            visible: true,
            tab: 'Salary Details'
        },

        {
            key: 'statutory_components',
            type: 'custom',
            visible: true,
            component: StatutoryDeductionsComponent,
            span: '100%',
            value: [],
            tab: 'Salary Details',
        },

        {
            key: 'statutory_components',
            type: 'custom',
            visible: true,
            component: NetIncomeHeading,
            span: '100%',
            tab: 'Salary Details',
        },

        // addButton('Add Deductions', 'add_deductions', 'deductions', {}, 'Salary Details'),
        {
            type: 'divider',
            heading: 'Role & Restrictions',
            visible: true,
            span: 24,
            tab: 'Employment Details'
        },

        {
            label: 'Role',
            key: 'role_id',
            type: 'select',
            placeholder: 'Enter roles',
            visible: true,
            width: '25%',
            tab: 'Employment Details',
            options: response.Roles || [],
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };
                OpenModalCustom({ newModule: "Roles", field_key: 'role_ids', array: true, params })
            },
        },

        {
            label: 'Employees (Will be able to see data of)',
            key: 'restrictions___Employees',
            type: 'select',
            placeholder: 'Select the employees',
            visible: true,
            width: '100%',
            options: [{ label: 'Self', value: 'self' }, { label: 'His/Her Team', value: 'team' }, { label: 'All', value: 'all' }, ...(response.Employees || [])],
            value: ["all"],
            multiple: true,
            tab: 'Employment Details'
        },

        {
            label: 'Locations (Will be able to see data of)',
            key: 'restrictions___Locations',
            type: 'select',
            placeholder: 'Select the Locations',
            visible: true,
            width: '25%',
            options: [{ label: 'All', value: 'all' }, ...(response.Locations || [])],
            value: ["all"],
            multiple: true,
            tab: 'Employment Details'
        },

        {
            label: 'Auto create ledger account with the same name as the contact?',
            key: 'auto_create_ledger_account',
            type: 'checkbox',
            placeholder: 'Enter the Opening Balance',
            visible: view ? false : isEdit ? false : true,
            width: '100%',
            tab: 'Ledger Account',
            value: isEdit ? false : true,
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                setObj({
                    updateVisiblity: Math.random()
                })
            }
        },
        {
            label: 'Ledger Account',
            key: 'account_id',
            type: 'select',
            placeholder: 'Enter the Ledger Account',
            visible: (connectedObject) => {
                if (isEdit) {
                    return true;
                }
                return !connectedObject.auto_create_ledger_account;
            },
            width: '25%',
            tab: 'Ledger Account',
            options: response.LedgerAccounts || []
        },
        {
            label: '',
            type: 'custom',
            placeholder: 'Enter the Ledger Account',
            visible: (connectedObject) => {
                if (view) {
                    return true;
                } else {
                    return false;
                }
            },
            width: '100%',
            tab: 'Ledger Account',
            component: LedgerAccountComponent
        },
    ];


    function adjustSalary(fieldChanged, getObj, setObj) {
        const obj = getObj();

        let monthly_ctc = +obj.annual_ctc || 0;

        const map = {
            Monthly: obj.salary_amount,
            Daily: obj.salary_amount * 30,
            Hourly: obj.salary_amount * 30 * 8,
        }

        const formula_map = {
            Monthly: '1 x Salary Amount',
            Daily: '30 x Salary Amount',
            Hourly: '30 x 8 x Salary Amount',
        }

        let allowances = obj.allowances || [];
        let deductions = obj.deductions || [];
        let statutory_components = obj.statutory_components || [];

        for (const allowance of allowances) {
            if (allowance.calculation_type == 'Percentage') {
                const calculation_value = allowance.calculation_value;
                allowance.amount = (calculation_value / 100) * obj.salary_amount;
            }
        }

        for (const deduction of deductions) {
            if (deduction.calculation_type == 'Percentage') {
                const calculation_value = deduction.calculation_value;
                deduction.amount = (calculation_value / 100) * obj.salary_amount;
            }
        }

        let gross_monthly = 0;
        let net_monthly = 0;

        if (fieldChanged != "annual_ctc") {
            monthly_ctc = 0;
            let basic_amount = 0;
            let allowances_amount = 0;
            let deductions_amount = 0;

            monthly_ctc += +(map[obj.salary_type]);
            gross_monthly += +(map[obj.salary_type]);
            basic_amount += +(map[obj.salary_type]);

            for (const allowance of allowances) {
                monthly_ctc += +allowance.amount;
                gross_monthly += +allowance.amount;
                allowances_amount += +allowance.amount;
            }

            for (const deduction of deductions) {
                monthly_ctc -= +deduction.amount;
                gross_monthly -= +deduction.amount;
                deductions_amount += +deduction.amount;
            }

            for (const statutory_component of statutory_components) {
                const nameFormMap = {
                    'EPF': calculateEPF,
                    'ESI': calculateESI,
                    'Professional Tax': calculateProfessionalTax,
                    'Labour Welfare Fund': calculateLabourWelfareFund,
                    'Income Tax': calculateIncomeTax,

                    // 'SDL': calculateSDL,
                    // 'SINDA': calculateSINDA,
                    // 'MBMF': calculateMBMF,
                    // 'CDAC': calculateCDAC,
                    // 'ECF': calculateECF,
                    // 'CPF': calculateCPF,
                };

                if (!nameFormMap[statutory_component.name]) {
                    continue;
                }

                const { employer, employee } = nameFormMap[statutory_component.name]({
                    gross_salary: gross_monthly,
                    basic: basic_amount,
                    allowances: allowances,
                    deductions: deductions,
                    statutory_component: statutory_component,
                });

                if (!statutory_component.override) {
                    statutory_component.employer_contribution = RND(employer);
                    statutory_component.employee_contribution = RND(employee);
                }

                net_monthly -= statutory_component.employee_contribution;
            }

            monthly_ctc *= 12;
        } else {
            map['Monthly'] = monthly_ctc / 12 / 2;
            map['Daily'] = map['Monthly'] / 30;
            map['Hourly'] = map['Daily'] / 8;

            obj.salary_amount = map[obj.salary_type];
            obj.basic_salary = map[obj.salary_type];
            obj.salary_formula = formula_map[obj.salary_type];

            const hraTest = allowances.find((item) => item.allowance_id == '64842b877b4ed84e27224002');
            const fixedAllowanceTest = allowances.find((item) => item.allowance_id == '64842b877b4ed84e27224009');

            if (!hraTest) {
                const actualHra = response.Earnings.find((item) => item._id == '64842b877b4ed84e27224002');
                allowances = [{
                    allowance_id: actualHra._id,
                    calculation_type: actualHra.calculation_type,
                    calculation_value: actualHra.calculation_value,
                }, ...allowances]
            }

            if (!fixedAllowanceTest) {
                const actualFixedAllowance = response.Earnings.find((item) => item._id == '64842b877b4ed84e27224009');
                allowances = [...allowances, {
                    allowance_id: actualFixedAllowance._id,
                    calculation_type: actualFixedAllowance.calculation_type,
                    calculation_value: 0,
                    amount: 0,
                }]
            } else {
                const actualFixedAllowance = allowances.find((item) => item.allowance_id == '64842b877b4ed84e27224009')
                fixedAllowanceTest.calculation_type = actualFixedAllowance.calculation_type;
                fixedAllowanceTest.calculation_value = 0;
                fixedAllowanceTest.amount = 0;
            }

            for (const allowance of allowances) {
                if (allowance.calculation_type == 'Percentage') {
                    const calculation_value = allowance.calculation_value;
                    allowance.amount = (calculation_value / 100) * obj.salary_amount;
                }
            }

            let total_allowances = 0;

            for (const allowance of allowances) {
                total_allowances += +allowance.amount;
            }

            const remaining_amount = (monthly_ctc / 12) - (obj.salary_amount + total_allowances);

            const fixedAllowance = allowances.find((item) => item.allowance_id == '64842b877b4ed84e27224009');

            fixedAllowance.calculation_value = remaining_amount;
            fixedAllowance.amount = remaining_amount;

            for (const deduction of deductions) {
                if (deduction.calculation_type == 'Percentage') {
                    const calculation_value = deduction.calculation_value;
                    deduction.amount = (calculation_value / 100) * obj.salary_amount;
                }
            }

            gross_monthly = monthly_ctc / 12;
        }

        setObj({
            annual_ctc: monthly_ctc,
            gross_monthly: gross_monthly,
            net_monthly: gross_monthly + net_monthly,
            basic_salary: map[obj.salary_type],
            salary_formula: formula_map[obj.salary_type],
            allowances,
            deductions,
            statutory_components,
            updateVisiblity: Math.random(),
        })
    }

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const employeeMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const employeeMain = {
    fields: employeefields,
    mobileCard: null
}

function calculateEPF({ gross_salary, basic, allowances, deductions, statutory_component }) {
    return {
        employer: basic * 0.12,
        employee: basic * 0.12,
    }
}

function calculateESI({ gross_salary, basic, allowances, deductions, statutory_component }) {
    return {
        employer: gross_salary * 0.0325,
        employee: gross_salary * 0.0075,
    }
}

function calculateProfessionalTax({ gross_salary }) {
    let employeeTax = 0;

    if (gross_salary <= 18750) {
        employeeTax = 0;
    } else if (gross_salary <= 25000) {
        employeeTax = 125;
    } else if (gross_salary <= 33333) {
        employeeTax = 166;
    } else {
        employeeTax = 208;
    }

    return {
        employer: 0,
        employee: employeeTax
    }
}

function calculateLabourWelfareFund({ gross_salary }) {
    if (gross_salary <= 15000) {
        return { employer: 30, employee: 10 }
    } else {
        return { employer: 0, employee: 0 }
    }
}

function calculateIncomeTax({ gross_salary, regime = "New" }) {
    let taxableIncome;
    let tax;
    let annual_salary = gross_salary * 12;

    // Old tax regime
    if (regime === "Old") {
        if (annual_salary <= 180000) {
            taxableIncome = 0;
        } else if (annual_salary <= 300000) {
            taxableIncome = annual_salary - 180000;
        } else if (annual_salary <= 500000) {
            taxableIncome = annual_salary - 300000;
        } else if (annual_salary <= 1000000) {
            taxableIncome = annual_salary - 500000;
        } else {
            taxableIncome = annual_salary - 1000000;
        }

        if (taxableIncome <= 180000) {
            tax = 0;
        } else if (taxableIncome <= 300000) {
            tax = taxableIncome * 0.1;
        } else if (taxableIncome <= 500000) {
            tax = 18000 + (taxableIncome - 300000) * 0.2;
        } else if (taxableIncome <= 1000000) {
            tax = 78000 + (taxableIncome - 500000) * 0.3;
        } else {
            tax = 228000 + (taxableIncome - 1000000) * 0.3;
        }
    } else {
        if (annual_salary <= 250000) {
            taxableIncome = 0;
        } else if (annual_salary <= 500000) {
            taxableIncome = annual_salary - 250000;
        } else if (annual_salary <= 1000000) {
            taxableIncome = annual_salary - 500000;
        } else {
            taxableIncome = annual_salary - 1000000;
        }

        if (taxableIncome <= 250000) {
            tax = 0;
        } else if (taxableIncome <= 500000) {
            tax = taxableIncome * 0.05;
        } else if (taxableIncome <= 1000000) {
            tax = 12500 + (taxableIncome - 500000) * 0.2;
        } else {
            tax = 112500 + (taxableIncome - 1000000) * 0.3;
        }
    }

    return {
        employer: 0,
        employee: tax / 12
    };

}
